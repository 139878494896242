import Vue from 'vue';
import App from './App.vue';

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_ApiUrl;

import router from './router';
import store from './store';

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import Pdf from "vue-pdf";
Vue.component("v-pdf", Pdf);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import DatePicker from "vuejs-datepicker";
Vue.component("date-picker", DatePicker);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
