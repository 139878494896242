<template>
  <div>
    <b-row class="mt-2 mb-3">
      <b-col>
        <h3>Reservations</h3>
      </b-col>
      <b-col cols="auto" class="text-right">
        <b-button
          variant="info"
          :disabled="busy"
          @click="getData">
          Refresh list
        </b-button>

        <b-button
          class="ml-2"
          variant="primary"
          @click="addReservation"
        >
          Add reservation
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          striped
          hover
          show-empty
          tbody-tr-class="reservations-row"
          :empty-text="busy ? 'Loading...' : 'There are no reservations yet.'"
          :items="tableItems"
          :fields="tableFields"
          :busy="busy"
          @row-clicked="rowClicked"
        >
          <template #cell(status)="data">
            <b :class="data.item.statusColor">
              {{ data.value }}
            </b>
          </template>

          <template #cell(startDate)="data">
            <strike v-if="data.item.cancelledUtc">
              {{ data.value }}
            </strike>
            <span v-else>
              {{ data.value }}
            </span>
          </template>

          <template #cell(endDate)="data">
            <strike v-if="data.item.cancelledUtc">
              {{ data.value }}
            </strike>
            <span v-else>
              {{ data.value }}
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>

  </div>
</template>

<style>
.reservations-row {
  cursor: pointer;
}
</style>

<script>

export default {
  name: "ReservationsList",

  metaInfo: {
    title: "Reservation management"
  },

  data() {
    return {
      tableFields: [
        { key: "createdUtc", label: "Created", sortable: true, sortKey: "createdUtcSorting", sortDirection: "desc"},
        { key: "id", label: "Number", sortable: true},
        { key: "name", label: "Name", sortable: true},
        { key: "startDate", label: "Start date", sortable: true, sortKey: "startDateSorting"},
        { key: "endDate", label: "End date", sortable: true, sortKey: "endDateSorting"},
        { key: "seats", label: "Seats", sortable: true},
        { key: "status", label: "Status", sortable: true}
      ],
      busy: false,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      this.busy = true;
      try {
        await this.$store.dispatch("reservations/load-reservations");
      } catch (error) {
        console.error(error);
      } finally {
        this.busy = false;
      }
    },

    rowClicked(item) {
      this.$router.push({ name: "Panel_Reservation_Edit", params: { reservationId: item.id }});
    },

    addReservation() {
      this.$router.push({ name: "Panel_Reservation_Add" });
    },

    mapTableItem(entity) {
      let result = {
        id: entity.id,
        createdUtc: new Date(entity.createdUtc).toLocaleString(),
        createdUtcSorting: new Date(entity.createdUtc),
        startDate: new Date(entity.startDate).toLocaleDateString(),
        startDateSroting: new Date(entity.startDate),
        endDate: new Date(entity.endDate).toLocaleDateString(),
        endDateSorting: new Date(entity.endDate),
        name: `${entity.firstname} ${entity.lastname}`,
        seats: entity.seats,
        confirmedUtc: entity.confirmedUtc,
        cancelledUtc: entity.cancelledUtc,
        status: "New",
        statusColor: "text-info"
      };

      if (entity.confirmedUtc) {
        result.status = entity.hasInvoice
          ? "Confirmed (Invoice issued)"
          : "Confirmed";

        result.statusColor = "text-success";
      }

      if (entity.cancelledUtc) {
        result.status = entity.hasCreditNote
          ? "Deleted (Credit Note issued)"
          : "Not reserved";
        result.statusColor = "text-danger";
      }

      return result;
    },
  },

  computed: {
    tableItems() {
      return this.$store.getters["reservations/list"].map(r => this.mapTableItem(r));
    },
  },
}
</script>
