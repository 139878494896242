import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

import BlankLayout from "../views/layouts/Blank.vue";
import PanelLayout from "../views/layouts/Panel.vue";

import Login from "../views/Login.vue";
import Reservations from "../views/Reservations.vue";
import Reservation from "../views/Reservation.vue";
import Invoice from "../views/Invoice.vue";
import CreditNote from "../views/CreditNote.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: BlankLayout,
    children: [
      {
        path: "",
        name: "Index_Login",
        component: Login
      }
    ]
  },

  {
    path: "/panel",
    component: PanelLayout,
    meta: {
      authorize: true
    },
    children: [
      {
        path: "",
        name: "Panel_Reservations",
        component: Reservations,
      },
      {
        path: "new-reservation",
        name: "Panel_Reservation_Add",
        component: Reservation,
      },
      {
        path: "reservation/:reservationId",
        name: "Panel_Reservation_Edit",
        props: true,
        component: Reservation,
      },
    ]
  },

  {
    path: "/panel/invoice/:reservationId",
    name: "Invoice",
    props: true,
    component: Invoice
  },

  {
    path: "/panel/credit-note/:reservationId",
    name: "CreditNote",
    props: true,
    component: CreditNote
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.authorize && !store.getters["user/is-authenticated"]) {
    next("/");
  } else {
    next();
  }
});

export default router;
