<template>
  <b-container>
    <b-row class="mt-4">
      <b-col class="text-center">
        <h4>
          <b-link :href="pdfUrl">Download</b-link>
        </h4>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <v-pdf :src="pdfUrl" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  name: "CreditNoteView",

metaInfo: {
    title: "Credit note"
  },

  props: ["reservationId"],

  data() {
    return {
      pdfUrl: null
    }
  },

  async created() {
    try {
      const blob = await axios
        .get(`/reservations/${this.reservationId}/creditnote`, { responseType: "blob" })
        .then((response) => response.data);

      this.pdfUrl = URL.createObjectURL(blob);
    } catch (error) {
      console.error(error);
    }
  }
}
</script>
