<template>
  <div>
    <b-navbar type="dark" variant="info" small="false">
      <b-navbar-brand>
        Admin panel
      </b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item href="https://nirvana.tours">Nirvana.tours website</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
          <b-button variant="primary" @click="logout">
            Log out
          </b-button>
      </b-navbar-nav>
    </b-navbar>

    <b-overlay :show="overlay" opacity="0.6">
      <b-container class="main-container" >
        <router-view />
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "PanelLayout",

  created() {
    if (!this.$store.getters["user/is-authenticated"]) {
      this.$router.push("/");
      return;
    }
  },

  methods: {
    async logout() {
      const confirmed = await this.$bvModal.msgBoxConfirm("Do you want to log out?", {
        centered: true,
        title: "Log out"
      });

      if (!confirmed) {
        return;
      }

      this.$store.commit("user/clear");
      this.$router.push("/");
    }
  },

  computed: {
    overlay() {
      return this.$store.getters["global/overlay"];
    }
  }
}
</script>

<style>

</style>
