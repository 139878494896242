<template>
  <div>
    <b-navbar type="dark" variant="info" small="false">
      <b-navbar-brand>
        Booking admin
      </b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item href="https://nirvana.tours">Nirvana.tours website</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <b-container class="main-container" fluid>
      <router-view />
    </b-container>
  </div>
</template>

<style lang="scss">
.main-container {
  padding: 10px 10px 10px 10px;
}
</style>

<script>
export default {
  name: "BlankLayout",
}
</script>


