<template>
  <div>
    <b-row class="mb-2 justify-content-sm-center">
      <b-col md=10 lg=8>
        <router-link :to="{ name: 'Panel_Reservations' }" >
          <h4>← Back to list</h4>
        </router-link>
      </b-col>
    </b-row>

    <b-row class="mb-4 justify-content-sm-center">
      <b-col md=10 lg=8>
        <h3 v-if="reservationId">Edit reservation</h3>
        <h3 v-else>Add reservation</h3>
      </b-col>
    </b-row>

    <b-row class="justify-content-sm-center">
      <b-col md=10 lg=8 >
        <b-row>
          <b-col sm=12 md=6>
            <b-form-group
              label-align="left"
              label="From"
            >
              <date-picker
                :disabled="reservation.confirmedUtc != null"
                :bootstrap-styling="true"
                :input-class="{ 'is-valid': validation.startDate, 'is-invalid': !validation.endDate }"
                :typeable="true"
                format="yyyy-MM-dd"
                :disabled-dates="disabledStartDates"
                :open-date="openStartDate"
                :highlighted="highlighted"
                v-model="reservation.startDate"
              />
            </b-form-group>

            <b-form-group
              label-align="left"
              label="First name"
            >
              <b-form-input
                required
                v-model="reservation.firstname"
                :state="validation.firstname"
                :readonly="reservation.confirmedUtc != null"
              />
            </b-form-group>

            <b-form-group
              label-align="left"
              label="ID number"
            >
              <b-form-input
                v-model="reservation.idNumber"
                :state="validation.idNumber"
                :readonly="reservation.confirmedUtc != null"
              />
            </b-form-group>

            <b-form-group
              label-align="left"
              label="E-mail"
            >
              <b-form-input
                v-model="reservation.email"
                :state="validation.email"
                :readonly="reservation.confirmedUtc != null"
              />
            </b-form-group>
          </b-col>

          <b-col sm=12 md=6>
            <b-form-group
              label-align="left"
              label="To"
            >
              <date-picker
                :disabled="reservation.confirmedUtc != null"
                :bootstrap-styling="true"
                :input-class="{ 'is-valid': validation.endDate, 'is-invalid': !validation.endDate }"
                :typeable="true"
                format="yyyy-MM-dd"
                :disabled-dates="disabledEndDates"
                :open-date="openEndDate"
                :highlighted="highlighted"
                v-model="reservation.endDate"
              />
            </b-form-group>

            <b-form-group
              label-align="left"
              label="Last name"
            >
              <b-form-input
                v-model="reservation.lastname"
                :state="validation.lastname"
                :readonly="reservation.confirmedUtc != null"
              />
            </b-form-group>

            <b-form-group
              label-align="left"
              label="Seats"
            >
              <b-form-spinbutton type="number" min="1" max="12"
                v-model="reservation.seats"
                :state="validation.seats"
                :readonly="reservation.confirmedUtc != null"
              />
            </b-form-group>

            <b-form-group
              label-align="left"
              label="Phone"
            >
              <b-form-input
                v-model="reservation.phone"
                :state="validation.phone"
                :readonly="reservation.confirmedUtc != null"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label-align="left"
              label="Payment as"
            >
              <b-form-radio-group
                :options="paymentOptions"
                v-model="reservation.legalEntity"
                :disabled="reservation.confirmedUtc != null"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              variant="primary"
              style="width: 100px"
              :disabled="reservation.confirmedUtc != null"
              @click="saveData"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="errors.length > 0">
          <b-col class="mt-2">
            <p v-for="(item, index) in errors" :key="`errors-${index}`" role="error" class="text-danger">
              <b>{{ item }}</b>
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <template v-if="reservationId">
      <b-row class="mt-4 mb-4 justify-content-sm-center">
        <b-col md=10 lg=8>
          <h3>Confirm reservation</h3>
        </b-col>
      </b-row>

      <b-row class="justify-content-sm-center">
        <b-col md=10 lg=8>
          <b-row>
            <b-col sm=12 md=6>
              <b-form-group inline
                label-align="left"
                label="Base price"
              >
                <b-form-input
                  :readonly="reservation.confirmedUtc != null"
                  v-model="reservation.basePrice"
                />
              </b-form-group>

              <b-form-group inline
                label-align="left"
                label="Confirmation status"
              >
                <b-form-input
                  readonly
                  v-model="confirmationStatus"
                />
              </b-form-group>

            </b-col>

            <b-col sm=12 md=6>
              <b-form-group inline
                label-align="left"
                label="Discount"
              >
                <b-form-input
                  :readonly="reservation.confirmedUtc != null"
                  v-model="reservation.discount"
                />
              </b-form-group>

              <b-form-group inline
                label-align="left"
                label="Invoice"
              >
                  <b-input-group>
                    <b-form-input
                      readonly
                      v-model="invoiceField"
                    />

                    <template #append>
                      <b-button
                        v-if="reservation.hasInvoice"
                        variant="primary"
                        :disabled="reservation.confirmedUtc == null"
                        @click="openInvoice">
                        View
                      </b-button>
                      <b-button
                        v-else
                        variant="info"
                        :disabled="reservation.confirmedUtc == null"
                        @click="issueInvoice">
                        Issue
                      </b-button>
                    </template>
                  </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                variant="primary"
                :disabled="busy || reservation.confirmedUtc != null || reservation.cancelledUtc != null"
                @click="confirmReservation"
              >
                Confirm
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-4 mb-4 justify-content-sm-center">
        <b-col md=10 lg=8>
          <h3>Cancel reservation</h3>
        </b-col>
      </b-row>

      <b-row class="justify-content-sm-center">
        <b-col md=10 lg=8>
          <b-row>
            <b-col sm=12 md=6>
              <b-form-group inline
                label-align="left"
                label="Cancellation status"
              >
                <b-form-input
                  readonly
                  v-model="cancellationStatus"
                />

              </b-form-group>
            </b-col>
            <b-col sm=12 md=6>
              <b-form-group inline
                label-align="left"
                label="Credit note"
              >
                  <b-input-group>
                    <b-form-input
                      readonly
                      v-model="creditNoteField"
                    />

                    <template #append>
                      <b-button
                        v-if="reservation.hasCreditNote"
                        variant="primary"
                        :disabled="reservation.cancelledUtc == null"
                        @click="openCreditNote">
                        View
                      </b-button>

                      <b-button
                        v-else
                        variant="info"
                        :disabled="reservation.cancelledUtc == null || !reservation.hasInvoice"
                        @click="issueCreditNote">
                        Issue
                      </b-button>
                    </template>
                  </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <b-button variant="warning" :disabled="busy || reservation.cancelledUtc != null" @click="cancelReservation">
                Cancel reservation
              </b-button>

              <b-alert
                class="mt-4"
                show
                variant="warning"
              >
                <p class="mb-0">
                  The reservation is <b>{{ reservation.confirmedUtc ? "confirmed" : "not confirmed" }}</b>,
                  invoice is <b>{{ reservation.hasInvoice ? "issued" : "not issued" }}.</b>
                </p>
                <p>The reserved dates will be open again</p>
              </b-alert>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-4 mb-4 justify-content-sm-center">
        <b-col md=10 lg=8>
          <h3>Delete reservation</h3>
        </b-col>
      </b-row>

      <b-row class="justify-content-sm-center">
        <b-col md=10 lg=8>
          <b-button variant="danger" :disabled="busy" @click="deleteReservation">
            Remove all data
          </b-button>

          <b-alert class="mt-4" show variant="danger">
            <p>Will delete all the data about this reservation including invoice and credit note.</p>
            <p>The reserved dates will be open again.</p>
            <p>This action cannot be reversed.</p>
          </b-alert>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<style lang="scss" scoped>
div[role="alert"] > p, p[role="error"] {
  margin-bottom: 0;
}
</style>

<script>
import axios from "axios";

export default {
  name: "ReservationEdit",

  props: {
    reservationId: {
      type: [Number, String],
      required: false
    }
  },

  data() {
    return {
      reservation: {
        id: null,
        startDate: null,
        endDate: null,
        firstname: null,
        lastname: null,
        idNumber: null,
        seats: null,
        email: null,
        phone: null,
        confirmedUtc: null,
        cancelledUtc: null,
        legalEntity: 2,
        basePrice: null,
        discount: null,
        hasInvoice: null,
        hasCreditNote: null,
      },

      paymentOptions: [
        { text: "Person", value: 2},
        { text: "Organization", value: 1}
      ],

      errors: [],

      busy: false,
    }
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      this.busy = true;
      await this.$store.dispatch("reservations/load-reserved-dates");

      try {
        if (this.reservationId) {
          const reservation = await axios
            .get(`/reservations/${this.reservationId}`)
            .then((response) => response.data);

          this.reservation = reservation;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.busy = false;
      }
    },

    getFormErrors() {
      const result = [];

      if (this.$store.getters["reservations/overlaps"](
            this.reservation.startDate,
            this.reservation.endDate,
            this.reservationId)) {
        result.push("The selected time period overlaps with other reservations.");
      }

      if (!this.valid) {
        result.push("Form data is invalid. Please correct and try again.");
      }

      return result;
    },

    async saveData() {
      this.errors = this.getFormErrors();

      if (this.errors.length > 0) {
        return;
      }

      this.busy = true;

      try {
        const response = this.reservationId
          ? await axios.put(`/reservations/${this.reservationId}`, this.reservationPut())
          : await axios.post("/reservations", this.reservationPost())

          this.reservation = response.data;

          if (!this.reservationId) {
            this.$router.push({ name: "Panel_Reservation_Edit", params: { reservationId: this.reservation.id }});
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.busy = false;
      }
    },

    async confirmReservation() {
      this.busy = true;

      try {
        const response = await axios
          .put(`/reservations/${this.reservation.id}/confirm`, {
            basePrice: this.reservation.basePrice,
            discount: this.reservation.discount
          });

          this.reservation = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.busy = false;
      }
    },

    async issueInvoice() {
      this.busy = true;

      try {
        const response = await axios.put(`/reservations/${this.reservation.id}/invoice/create`);
        this.reservation = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.busy = false;
      }
    },

    openInvoice() {
      if (this.reservation.id == null) {
        alert("No reservation selected");
        return;
      }

      const url = this.$router.resolve({ name: "Invoice", params: { reservationId: this.reservation.id}});
      window.open(url.href, "_blank");
    },

    async cancelReservation() {
      const confirmed = await this.$bvModal.msgBoxConfirm("Do you want to cancel this reservation?", {
        centered: true,
        title: "Cancel reservation"
      });

      if (confirmed != true)
        return;

      this.busy = true;

      try {
        const response = await axios.put(`/reservations/${this.reservation.id}/cancel`)
        this.reservation = response.data;
      } catch (error) {
        console.eror(error);
      } finally {
        this.busy = false;
      }
    },

    async issueCreditNote() {
      this.busy = true;

      try {
        const response = await axios.put(`/reservations/${this.reservation.id}/creditnote/create`);
        this.reservation = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.busy = false;
      }
    },

    openCreditNote() {
      if (this.reservation.id == null) {
        alert("No reservation selected");
        return;
      }

      const url = this.$router.resolve({ name: "CreditNote", params: { reservationId: this.reservation.id}});
      window.open(url.href, "_blank");
    },

    async deleteReservation() {
      const confirmed = await this.$bvModal.msgBoxConfirm("Do you want to delete this reservation?", {
        centered: true,
        title: "Delete reservation"
      });

      if (confirmed != true)
        return;

      try {
        await axios.delete(`/reservations/${this.reservation.id}/delete`);

        this.$router.push({name: "Panel_Reservations"});
      } catch (error) {
        console.error(error)
      } finally {
        this.busy = false;
      }
    },

    reservationPost() {
      return {
        id: this.reservation.id,
        startDate: this.reservation.startDate?.toISOString().substring(0, 10),
        endDate: this.reservation.endDate?.toISOString().substring(0, 10),
        firstname: this.reservation.firstname,
        lastname: this.reservation.lastname,
        idNumber: this.reservation.idNumber,
        seats: this.reservation.seats,
        email: this.reservation.email,
        phone: this.reservation.phone,
        legalEntity: this.reservation.legalEntity
      }
    },

    reservationPut() {
      return {
        ...this.reservationPost,
        basePrice: this.reservation.basePrice,
        discount: this.reservation.discount,
      }
    },
  },

  computed: {
    minDate() {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      return date
    },

    validation() {
      return {
        startDate: this.reservation.startDate != null
          && this.reservation.endDate != null
          && this.reservation.startDate <= this.reservation.endDate,

        endDate: this.reservation.startDate != null
          && this.reservation.endDate != null
          && this.reservation.endDate >= this.reservation.startDate,

        firstname: this.reservation.firstname != null && this.reservation.firstname.length > 0,
        idNumber: this.reservation.idNumber != null && this.reservation.idNumber.length > 0,
        lastname: this.reservation.lastname != null && this.reservation.lastname.length > 0,

        seats: this.reservation.seats != null
          && this.reservation.seats > 0
          && this.reservation.seats < 13,

        email: this.reservation.email != null && this.reservation.email.length > 0,
        phone: this.reservation.phone != null && this.reservation.phone.length > 0,
      }
    },

    valid() {
      return this.validation.startDate
        && this.validation.endDate
        && this.validation.firstname
        && this.validation.idNumber
        && this.validation.lastname
        && this.validation.seats
        && this.validation.email
        && this.validation.phone;
    },

    confirmationStatus() {
      if (this.reservation.confirmedUtc != null) {
        return this.reservation.hasInvoice
          ? "Confirmed (Invoice issued)"
          : "Confirmed";
      } else {
        return "Not confirmed";
      }
    },

    invoiceField() {
      return this.reservation.hasInvoice
        ? `${this.reservation.confirmedUtc.substring(0, 10)}#${this.reservation.id}`
        : "Not issued";
    },

    cancellationStatus() {
      if (this.reservation.cancelledUtc != null) {
        return this.reservation.hasCreditNote
          ? "Deleted (Credit Note issued)"
          : "Not reserved";
      } else {
        return "Not cancelled";
      }
    },

    creditNoteField() {
      return this.reservation.hasCreditNote
        ? `${this.reservation.confirmedUtc.substring(0, 10)}#${this.reservation.id}`
        : "Not issued";
    },

    disabledStartDates() {
      const disabledDates = {
        ranges: this.$store.getters["reservations/disabled-dates-ranges"](this.reservationId),
        to: new Date()
      };

      disabledDates.to.setDate(disabledDates.to.getDate() + 14);

      return disabledDates;
    },

    openStartDate() {
      const result = new Date();
      result.setDate(result.getDate() + 14);

      for (let i = 0; i < 365; i++) {
        if (this.$store.getters["reservations/falls-into-reserved"](this.reservationId,  result)) {
          result.setDate(result.getDate() + 1);
        } else {
          break;
        }
      }

      return result;
    },

    disabledEndDates() {
      const disabledDates = {
        ranges: this.$store.getters["reservations/disabled-dates-ranges"](this.reservationId),
        to: null
      };

      if (this.reservation.startDate) {
        const to = new Date(this.reservation.startDate);
        to.setDate(to.getDate() + 3);
        disabledDates.to = to;
      } else {
        const to = new Date();
        to.setDate(to.getDate() + 17);
        disabledDates.to = to;
      }

      return disabledDates;
    },

    openEndDate() {
      if (this.reservation.startDate) {
        const date = new Date(this.reservation.startDate);
        date.setDate(date.getDate() + 3);
        return date;
      } else {
        const date = new Date();
        date.setDate(date.getDate() + 17);
        return date;
      }
    },

    highlighted() {
      if (this.reservation.startDate && this.reservation.endDate) {
        return {
          from: this.reservation.startDate,
          to: this.reservation.endDate
        };
      } else {
        return {}
      }
    }
  },

  watch: {
    busy(value) {
      this.$store.commit("global/overlay", value);
    },
  },
}
</script>
