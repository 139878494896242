import axios from "axios";

export default {
  namespaced: true,

  state: {
    username: null,
    jwt: null,
    expires: null
  },

  getters: {
    ["is-authenticated"]: (state) => {
      const expiresDate = new Date(state.expires);

      return expiresDate.getTime() > new Date().getTime();
    }
  },

  mutations: {
    set(state, { username, jwt, expires }) {
      state.username = username;
      state.jwt = jwt;
      state.expires = expires;

      localStorage.setItem("username", username);
      localStorage.setItem("jwt", jwt);
      localStorage.setItem("expires", expires);

      axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("jwt")}`;
    },

    clear(state) {
      state.username = null;
      state.jwt = null;
      state.expires = null;

      localStorage.removeItem("username");
      localStorage.removeItem("jwt");
      localStorage.removeItem("expires");

      delete axios.defaults.headers["Authorization"];
    },

    restoreState(state) {
      const jwt = localStorage.getItem("jwt");

      if (jwt) {
        state.username = localStorage.getItem("username");
        state.jwt = jwt;
        state.expires = localStorage.getItem("expires");
      }
    }
  },

  actions: {
    restore(state) {
      const jwt = localStorage.getItem("jwt");

      if (jwt) {
        state.commit("set", {
          jwt,
          username: localStorage.getItem("username"),
          expires: localStorage.getItem("expires")
        });
      }
    }
  }
}
