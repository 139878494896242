import axios from "axios";

export default {
  namespaced: true,

  state: {
    reservedDates: [],
    reservations: [],
  },

  mutations: {
    ["reserved-dates/set"](state, dates) {
      state.reservedDates = dates
        ? dates
        : [];
    },

    ["reservations/set"](state, reservations) {
      state.reservations = reservations
        ? reservations
        : [];
    },
  },

  getters: {
    ["list"]: (state) => {
      return state.reservations;
    },

    ["item"]: (state) => (id) => {
      for (const reservation in state.reservations) {
        if (reservation.id == id) {
          return reservation;
        }
      }
      return null;
    },

    ["disabled-dates-ranges"]: (state) => (id) => {
      return state.reservedDates
        .filter(r => !(id && r.id == id))
        .map(r => {
          const endDate = new Date(r.endDate);
          endDate.setDate(endDate.getDate() + 2);

          return {
            from: new Date(r.startDate),
            to: endDate
          };
      });
    },

    ["falls-into-reserved"]: (state) => (id, date) => {
      return state.reservedDates
        .filter(r => !(id && r.id == id))
        .some(r => new Date(date) >= new Date(r.startDate) &&
                   new Date(date) <= new Date(r.endDate)
        );
    },

    ["overlaps"]: (state) => (startDate, endDate, reservationId) => {
      let reservedDates = state.reservedDates;

      if (reservationId) {
        reservedDates = reservedDates.filter(r => r.id != reservationId);
      }

      return reservedDates.some(r =>
        new Date(startDate) <= new Date(r.endDate) &&
        new Date(endDate) >= new Date(r.startDate)
      );
    }
  },

  actions: {
    async ["load-reservations"](store) {
      try {
        const reservations = await axios.get("/reservations");

        store.commit("reservations/set", reservations.data);
      } catch (error) {
        console.error(error);
      }
    },

    async ["load-reserved-dates"](store) {
      try {
        const response = await axios.get("/reserved-dates");

        store.commit("reserved-dates/set", response.data);
      } catch (error) {
        console.error(error);
      }
    },
  }
}
