<template>
  <b-overlay :show="requesting" rounded="lg" opacity="0.6">
  <b-card class="centered-card mt-5">
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="secondary"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="secondary"></b-spinner>
          <span class="sr-only">Please wait...</span>
        </div>
      </template>
      <b-form @submit.prevent="onLogin">
        <b-form-group label="Login:">
          <b-form-input
            type="text"
            placeholder="Enter login"
            required
            v-model="username"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Your Name:">
          <b-form-input
            type="password"
            placeholder="Enter password"
            required
            v-model="password"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" :disabled="requesting" variant="primary" class="mr-2">Login</b-button>

        <b v-for="(item, index) in errors" :key="`errors-${index}`" class="text-danger text-small">
          {{ item }}
        </b>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<style lang="scss">
  .centered-card {
    margin: auto;
    width: 400px;
  }

</style>

<script>
import axios from "axios";

export default {
  name: "LoginView",
  metaInfo: {
    title: "nirvana.tours - Booking admin panel"
  },

  data() {
    return {
      username: "",
      password: "",
      errors: [],
      requesting: false
    };
  },

  created() {
    this.reroute();
  },

  methods: {
    async onLogin () {
      this.errors = [];
      this.requesting = true;

      try {
        const user = await axios
          .post("/auth", {
            username: this.username,
            password: this.password
          })
          .then((response => response.data));

        this.$store.commit("user/set", user);
        this.reroute();

      } catch (error) {
        if (error.response?.status == 401) {
          this.errors.push("Unauthorized");
        }
        if (error.response?.data.errors) {
          this.errors = [
            ...this.errors,
            ...error.response.data.errors
          ];
        }

      } finally {
        this.requesting = false;
      }
    },

    reroute() {
      if (this.$store.getters["user/is-authenticated"]) {
        this.$router.push("/panel");
      }
    }
  }
}
</script>
