import Vue from 'vue'
import Vuex from 'vuex'

import user from "./modules/user.js";
import reservations from "./modules/reservations.js";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    reservations
  },

  state: {
    global: {
      overlay: false,
    }
  },

  mutations: {
    ["global/overlay"](state, value) {
      state.global.overlay = value;
    }
  },

  getters: {
    ["global/overlay"]: (state) => state.global.overlay,
  },

  actions: {},
})

store.dispatch("user/restore");

export default store;
